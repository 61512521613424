<template>
  <div class="constrain part">
    <div class="row-12">
      <div class="md:col-8 md:offset-2 mt-12 sm:mt-24 md:mt-36">
        <p class="parent-page-title text-center uppercase">
          - {{ parentPageName }} -
        </p>
      </div>
      <div class="md:col-8 md:offset-2">
        <h1 class="mt-8 md:mt-12 text-center" v-html="payload.seitentitel" />
      </div>
      <div class="sm:offset-1 sm:col-10 lg:col-8 lg:offset-2">
        <div class="subtitle" v-html="payload.untertitel" />
      </div>
    </div>
  </div>
</template>

<script>

import Poi from '../../assets/images/poi.svg';
import Phone from '../../assets/images/phone.svg';
import Mail from '../../assets/images/email.svg';

export default {
  components: {
    Poi,
    Phone,
    Mail,
  },
  props: {
    payload: Object,
  },
  computed: {
    parentPageName() {
      return this.payload.parentTitle;
    },
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>
.parent-page-title {
  font-size: px(18);
  color: var(--color-blue-gray);
  font-weight: 100;
}

.subtitle {
  &:deep(p) {
    text-align: center;
    line-height: 1.75;
  }
}
</style>
